/**
 * Created by rsimm on 1/30/2016.
 */
"use strict";

var MoreInformationContact = React.createClass({
    onSubmitHandler: function(e){
        e.preventDefault(); // Keep the page from submitting...
        var data = {
            name: $('#name').val(),
            email: $('#email').val(),
            subject: $('#subject').val(),
            message: $('#message').val()
        };
        //alert(data.name + ' ' + data.email + ' : Subject: ' + data.subject + ' : Message: ' + data.message);
        $.post("/components/php_ajax/more_information_phpmailer.php?q=email", data)
            .done(function (data) {
                $("#customer_message").html(data);
            });
        $.post("/")
    },
    render: function (){
        return (
            <div className="col-lg-3 col-md-4 col-sm-4">
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h3 className="panel-title"><i className="fa fa-comment" /> More Information</h3>
                    </div>
                    <div className="panel-body">
                        <h4>Mailing Address:</h4>
                        <address>
                            TCS Consultants, Inc.<br/>
                            P.O. Box 884<br/>
                            Montgomery, TX 77356
                        </address>
                        <div>
                            <i className="fa fa-envelope">&nbsp;</i>
                            <a href="mailto:tcs@tcstx.com">tcs@tcstx.com</a>
                        </div>
                        <div>
                            <i className="fa fa-phone">&nbsp;</i>
                            <a href="tel:9365883200">(936) 588-3200</a>
                        </div>
                        <hr/>
                        <h4>Contact Form:</h4>

                        <form id="myForm" name="myForm" onSubmit={this.onSubmitHandler}>
                            <div className="form-group">
                                <label className="sr-only" htmlFor="name">Name:</label>
                                <input type="text" className="form-control" id="name"
                                       placeholder="Full Name" required/>
                            </div>
                            <div className="form-group">
                                <label className="sr-only" htmlFor="email">Email Address:</label>
                                <input type="email" className="form-control" id="email"
                                       placeholder="Email Address" required/>
                            </div>
                            <div className="form-group">
                                <label className="sr-only" htmlFor="subject">Subject:</label>
                                <input type="text" className="form-control" id="subject"
                                       placeholder="Subject" required/>
                            </div>
                            <div className="form-group">
                                <label className="sr-only" htmlFor="message">Message</label>
                    <textarea className="form-control" id="message"
                              placeholder="Detailed Message" required/>
                            </div>
                            <button id="btnEmail" type="submit" className="btn btn-primary pull-right">
                                <i className="fa fa-paper-plane"/> Send
                            </button>
                            <br/><br/>

                            <div id="customer_message" className="alert-success"></div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
});

ReactDOM.render(<MoreInformationContact />, document.getElementById('moreInformationContact'));